import { Suspense, lazy } from "react";
import { Routes, Route } from "react-router-dom";

import './App.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import CCTV from "./Pages/CCTV/CCTV";

const Navigation = lazy(() => import('./Navigation/Navigation'));
// const SmartHomeBlog = lazy(() => import("./Pages/Blogs/SmartHomeBlog"));
const SMATV = lazy( () => import("./Pages/SMATV/SMATV"));
const AccessControl = lazy(() => import("./Pages/AccessControl/AccessControl"));
const ComputerNetwork = lazy(() => import("./Pages/ComputerNetwork/ComputerNetwork"));
const Intercom = lazy(() => import("./Pages/Intercom/Intercom"));
const TurnstileGate = lazy(() => import("./Pages/TurnstileGate/TurnstileGate"));
const PublicAddressSystem = lazy(() => import("./Pages/PublicAddressSystem/PublicAddressSystem"));
const PabxSystem = lazy(() => import("./Pages/PabxSystem/PabxSystem"));
const SmartHomeAutomationSystem = lazy(() => import("./Pages/SmartHomeAutomationSystem/SmartHomeAutomationSystem"));
const AutomaticGate = lazy(() => import("./Pages/AutomaticGate/AutomaticGate"));
const Home = lazy(() => import("./Pages/Home/Home"));
const Footer = lazy(() => import("./Footer/Footer"));
const About = lazy(() => import("./Pages/About/About"));
const PrivacyPolicy = lazy(() => import("./Pages/PrivacyPolicy/PrivacyPolicy"));
const Blogs = lazy(() => import("./Pages/Blogs/Blogs"));
const BlogPage = lazy(() => import("./Pages/Blogs/BlogPage"));
const BlogPageCCTV = lazy(() => import("./Pages/Blogs/BlogPageCCTV"));
const LoadingAnimation = lazy(() => import("./Components/LoadingAnimation/LoadingAnimation"));
// const BarriesGate = lazy(() => import("./Pages/BarriesGate/BarriesGate"));

function App() {
  return (
    <div className="App">
      <Suspense fallback={<div><LoadingAnimation /></div>}>
        <Navigation />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/privacypolicy" element={<PrivacyPolicy />} />
          <Route path="/blogs" element={<Blogs />} />
          <Route path="/blogpageSecurityCameras" element={<BlogPage />} />
          <Route path="/blogpageCCTVCameras" element={<BlogPageCCTV />} />
          {/* <Route path="/blogpageSmartHomeSolution" element={<SmartHomeBlog />} /> */}
          <Route path="/smatv" element={<SMATV />} />
          <Route path="/cctv" element={<CCTV />} />
          <Route path="/accesscontrol" element={<AccessControl />} />
          <Route path="/computernetwork" element={<ComputerNetwork />} />
          <Route path="/intercom" element={<Intercom />} />
          <Route path="/turnstilegate" element={<TurnstileGate />} />
          <Route path="/publicaddresssystem" element={<PublicAddressSystem />} />
          <Route path="/pabxsystem" element={<PabxSystem />} />
          <Route path="/smarthomeautomationsystem" element={<SmartHomeAutomationSystem />} />
          <Route path="/automaticgate" element={<AutomaticGate />} />
        </Routes>
        <Footer />
      </Suspense>
    </div>
  );
}

export default App;
