import React from 'react'

import cctv from "../../Image/cctv.png";
import cctv1 from "../../Image/camera.jpg";

const CCTV = () => {
    return (
        <div id='cctv' className='w-full h-auto overflow-hidden '>
            <div className='container mx-auto max-w-[75rem] py-16 px-3'>
                <h1 className='text-2xl sm:text-4xl lg:text-5xl font-bold mb-6 leading-tight'>Comprehensive CCTV Camera Solutions for Enhanced Security</h1>
                <div class="grid grid-cols-1 md:grid-cols-2 grid-flow-row md:grid-flow-col gap-y-4 md:gap-4">
                    <div className='flex items-center justify-center'>
                        <img src={cctv} alt='cctv' className='h-80' />
                    </div>
                    <div className='flex items-center justify-center text-lg sm:text-2xl'>
                        Welcome to our premier CCTV camera services, where security meets innovation. Our cutting-edge CCTV camera solutions offer unparalleled surveillance capabilities to safeguard your premises effectively.
                        With a diverse range of cameras tailored to meet various needs, we ensure comprehensive coverage for homes, businesses, and public spaces.
                    </div>
                </div>
                <div className='grid grid-cols-1 text-lg sm:text-2xl gap-y-6'>
                    <h5 className='text-xl sm:text-2xl lg:text-3xl font-bold mt-6'>Key Features:</h5>
                    <ul className='list-disc flex flex-col w-[90%] mx-auto  gap-y-5'>
                        <li><strong>High Definition Clarity:</strong> Our CCTV cameras deliver crystal-clear video footage, enabling you to monitor every detail with precision.</li>
                        <li><strong>Night Vision Technology:</strong> Equipped with advanced infrared technology, our cameras provide clear visibility even in low-light conditions, ensuring round-the-clock surveillance.</li>
                        <li><strong>Motion Detection:</strong> Stay vigilant with our motion detection feature, which triggers instant alerts upon detecting any suspicious activity, keeping your property secure at all times.</li>
                        <li><strong>Remote Monitoring:</strong> Monitor your premises remotely from anywhere in the world with our user-friendly mobile app or web interface, ensuring peace of mind even when you're away.</li>
                    </ul>
                    <div className='py-4 flex items-center justify-center'><img src={cctv1} alt='cctv1' /></div>
                    <div>
                        <h5 className='text-xl sm:text-2xl lg:text-3xl font-bold mb-4'>Key Features:</h5>
                        <ul className='list-disc flex flex-col w-[90%] mx-auto  gap-y-5'>
                            <li><strong>Weatherproof Design:</strong> Designed to withstand harsh weather conditions, our weatherproof CCTV cameras ensure reliable performance, indoors or outdoors.</li>
                            <li><strong>Easy Installation:</strong> Our expert technicians ensure hassle-free installation, ensuring that your CCTV system is up and running smoothly in no time.</li>
                            <li><strong>Customized Solutions:</strong> Whether you need a single camera setup or a comprehensive surveillance network, we offer customized solutions tailored to your specific requirements.</li>
                            <li><strong>Expert Support</strong> Count on our dedicated support team to provide prompt assistance and maintenance services, ensuring optimal performance of your CCTV system.</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CCTV;
